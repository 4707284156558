<div class="visualisation-tool-panel-container">
  <div class="form-container fx-layout-col fx-justify-space-evenly fx-align-center">
    <form novalidate [formGroup]="visualisationForm" class="fx-layout-col fx-justify-space-evenly fx-align-center"
      autocomplete="off">
      <div class="field-label fx-flex-100-33-percent">
        {{ 'LABEL.APPLIED_ASSET_TYPE_VISUALISATION' | translate }}
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <mat-form-field appearance="outline">
          <mat-select formControlName="currentAssetType"
            [id]="'input-current-asset-type-visualisation'">
            <mat-option [value]="AssetType.NullAssetType">{{ 'LABEL.ALL_ASSET_TYPE_VISUALISATION' | translate }}</mat-option>
            @for (assetType of assetTypes; track assetType.id) {
              <mat-option [value]="assetType">
                {{ assetType.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field-label fx-flex-100-33-percent">
        {{ 'LABEL.APPLIED_VISUALISATION' | translate }}
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <mat-form-field appearance="outline">
          <mat-select formControlName="currentVisualisation" [id]="'input-current-visualisation'" required>
            @for (datagridVisualisation of assetTypeVisualisations$ | async; track datagridVisualisation.id) {
              <mat-option [value]="datagridVisualisation">
                {{ datagridVisualisation.name }}
              </mat-option>
            }
          </mat-select>
          @if (visualisationForm.controls.currentVisualisation.hasError('required')) {
            <mat-error>
              {{ 'ERROR.FIELD_REQUIRED' | translate }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    </form>
    @if (gridStateService.gridStateModified) {
      <div [id]="'visualisation_changed_label'">
        {{ 'LABEL.VISUALISATION_CHANGED' | translate }}
      </div>
    }
    @if (!visualisationForm.controls.currentVisualisation.value?.isDefault) {
      <div class="fx-layout-col fx-align-center fx-justify-space-evenly fx-gap-10">
        @if (gridStateService.gridStateModified) {
          <button (click)="updateVisualisation()" [id]="'visualisation-update-button'"
            color="accent"
            mat-raised-button>{{ 'BUTTON.UPDATE_VISUALISATION' | translate }}
          </button>
        }
        <button (click)="deleteVisualisation()" [id]="'visualisation-delete-button'"
          mat-stroked-button>{{ 'BUTTON.DELETE_VISUALISATION' | translate }}
        </button>
      </div>
    }
  </div>
  <div class="button-container fx-layout-col fx-align-center fx-justify-space-evenly fx-gap-10">
    <button (click)="createVisualisation()" [id]="'visualisation-create-button'"
      mat-stroked-button>{{ 'BUTTON.SAVE_VISUALISATION' | translate }}
    </button>
  </div>
</div>
