import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { ClientSubscription } from '@app/core/model/client/client-subscription';
import { Group } from '@app/core/model/client/group';
import { User } from '@app/core/model/client/user';
import { Entity } from '@app/core/model/entities/entity';
import { Expose, Type } from 'class-transformer';
import dayjs from 'dayjs';

export class Client extends Entity {

  public objectType = EntityTypeEnum.CLIENT;
  public isTb: boolean;
  public organizationId: string;

  @Type(() => Group)
  public groups: Group[];
  @Type(() => User)
  public users: User[];
  @Type(() => ClientSubscription)
  public subscriptions: ClientSubscription[] = [];
  @Type(() => ClientSubscription)
  public subscription: ClientSubscription;

  /*
  Client subscription management
   */
  @Expose()
  public get currentSubscription(): ClientSubscription | null {
    return this.subscriptions.singleOrNull((subscription) => {
      return dayjs().isAfter(subscription.startDate) && dayjs().isBefore(subscription.endDate);
    });
  }

  @Expose()
  public get futureSubscription(): ClientSubscription | null {
    return this.subscriptions.singleOrNull((subscription) => {
      return dayjs().isBefore(subscription.startDate);
    });
  }

  @Expose()
  public get mostRecentSubscription(): ClientSubscription | null {
    return this.subscriptions.lastOrNull((subscription) => {
      return dayjs().isSame(subscription.startDate) || dayjs().isAfter(subscription.startDate);
    });
  }

  @Expose()
  public get configurableSubscription(): ClientSubscription | null {
    return this.subscriptions.firstOrNull((subscription) => {
      return dayjs().isSame(subscription.endDate) || dayjs().isBefore(subscription.endDate);
    });
  }

  @Expose()
  public get displayableSubscription(): ClientSubscription {
    if (this.configurableSubscription) {
      return this.configurableSubscription;
    } else {
      return this.mostRecentSubscription;
    }
  }

  @Expose()
  public get pastSubscriptions(): ClientSubscription[] {
    return this.subscriptions.filter((subscription) => dayjs().isAfter(subscription.endDate));
  }

  @Expose()
  public toString() {
    return this.name;
  }
}

export type AddUserInput = { id: number, modules?: string[], groupId?: number };
