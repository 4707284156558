import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { PowerBIReportConfig } from '@app/core/model/other/powerBI';
import { TokenRefreshService } from '@app/shared/services/token-refresh.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { PowerBIService } from '@services/powerBI.service';
import { IReportEmbedConfiguration } from 'embed';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'powerBI-report',
  templateUrl: './powerBI-report.component.html',
  styleUrls: ['./powerBI-report.component.scss']
})
export class PowerBIReportComponent implements OnInit, OnDestroy {

  @Input() public workspaceId: string;
  @Input() public reportId: string;
  @Input() public reportDefaultPage: string;
  @Input() public assetId?: string;
  @ViewChild('powerBI') public powerBI: ElementRef;
  @Input() public relatedEntityType: EntityTypeEnum;
  public powerBIReportConfig: PowerBIReportConfig & IReportEmbedConfiguration;
  public exportInProgress = false;
  private destroy$ = new Subject<void>();
  public Permission = PermissionEnum;

  private powerBIService = inject(PowerBIService);
  private snackbar = inject(SnackbarManager);
  private translate: TranslateService = inject(TranslateService);
  private analyticsService = inject(AnalyticsService);
  private accessManager = inject(AccessManager);
  private tokenRefreshService = inject(TokenRefreshService);

  /**
   * Load the BI report.
   */
  public ngOnInit(): void {
    this.tokenRefreshService.startTokenRefresh();
    this.powerBIService.loadPowerBIReportConfig(this.workspaceId, this.reportId).pipe(first()).subscribe(
      powerBIReportConfig => {
        // Filter by Users
        powerBIReportConfig.filters.push(PowerBIReportConfig.buildFilter(
          'user',
          'id',
          this.accessManager.currentUser.id
        ));

        // Filter by Asset
        if (!!this.assetId) {
          powerBIReportConfig.filters.push(PowerBIReportConfig.buildFilter('assets', '_id', this.assetId));
        }

        this.powerBIReportConfig = powerBIReportConfig;
        this.powerBIReportConfig.pageName = this.reportDefaultPage;
        this.analyticsService.trackEvent(new AnalyticsEvent(ActionEnum.READ, this.relatedEntityType)
          .addProperties({
            [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
            [AnalyticsKeyEnum.REPORT_ID]: this.reportId
          }));
      });
  }

  /**
   * Call the service to print the powerBI report in pdf.
   */
  public exportBIReport(): void {
    this.powerBIService.exportPowerBIReports(
      this.workspaceId,
      this.reportId,
      this.powerBIReportConfig.filters
    )
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.analyticsService.trackEvent(
            new AnalyticsEvent(ActionEnum.EXPORT, this.relatedEntityType)
              .addProperties({
                [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
                [AnalyticsKeyEnum.REPORT_ID]: this.reportId
              })
          );
        })
      )
      .subscribe(() =>
        this.snackbar.showActionSnackbar(this.translate.instant('SUCCESS.SEND_EMAIL_EXPORT_REPORTS'))
      );
  }

  /**
   * Toggle full screen mode for the PowerBI report
   */
  public toggleFullScreen(): void {
    !document.fullscreenElement ? this.powerBI.nativeElement.requestFullscreen() : document.exitFullscreen();
  }

  /**
   * Destroy all charts and end subscriptions
   */
  public ngOnDestroy(): void {
    this.tokenRefreshService.stopTokenRefresh();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
