<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ equipment?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeEquipmentSidebar()" [disableRipple]="true"
            class="fx-layout-col fx-justify-center fx-align-center"
            id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadEquipmentDocuments()"
        [class]="'mdi mdi-paperclip sm'"
        [id]="'upload-equipment-document-button'"
        [matTooltip]="'BUTTON.ADD_DOCUMENT' | translate"
        [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="uploadEquipmentPictures()"
        [class]="'mdi mdi-image-plus sm'"
        [id]="'upload-equipment-picture-button'"
        [matTooltip]="'BUTTON.ADD_PICTURE' | translate"
        [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="deleteEquipment()"
        [class]="'mdi mdi-delete sm'"
        [id]="'delete-equipment-button'"
        [matTooltip]="'BUTTON.DELETE' | translate"
        [permissions]="[Permission.DELETE_EQUIPMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="duplicateEquipments()"
        [class]="'mdi mdi-content-copy sm'"
        [id]="'duplicate-equipment-button'"
        [matTooltip]="'BUTTON.DUPLICATE' | translate"
        [permissions]="[Permission.CREATE_EQUIPMENT]">
      </restricted-icon-button>

      <span class="fx-flex"></span>

      <div class="equipment-sheet-link">
        <span (click)="navigateToEquipmentSheet()">{{ 'BUTTON.SHOW_EQUIPMENT_SHEET' | translate }}</span>
      </div>
    </div>
  </div>
  <div cdk-scrollable class="sidebar-sheet fx-flex-grow">
    <div class="sidebar-picture-container fx-layout-col fx-justify-center fx-align-center">
      <img [alt]="images[0] ? images[0].alt : 'LABEL.NO_EQUIPMENT_PICTURE' | translate"
        [src]="images[0]?.url || appConfig.PLACEHOLDER_CAROUSEL"
        id="sidebar-picture"/>
    </div>
    <div class="page-sidebar">
      <div class="form-container">
        @if (equipment) {
          <form-builder
            [displayHeader]="false"
            [eventsOrigin]="eventsOrigin"
            [formId]="formId"
            [permissionsForEdition]="permissionsForEdition">
          </form-builder>
        }
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{ 'LABEL.DOCUMENT' | translate }} </span>
        <restricted-text-button
          (onClick)="uploadEquipmentDocuments()"
          [id]="'equipments-upload-document-button'"
          [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{ 'BUTTON.ADD_DOCUMENT' | translate }}
        </restricted-text-button>
      </div>
      @for (document of equipment?.documents; track document.id) {
        <div class="document-content">
          @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
            <a (click)="fileService.downloadFile(document.id)"
              >
              <i class="mdi mdi-file-outline xs"></i>
              <span class="file-name-label">{{ document.name }}</span>
            </a>
          } @else {
            <span class="file-name-label">{{ document.name }}</span>
          }
          <span (click)="deleteEquipmentDocument(document)"
            [permission-lock]="[Permission.EDIT_EQUIPMENT, Permission.DELETE_DOCUMENT]" class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
        </div>
      }
    </div>
  </div>
</div>
