import { inject, Injectable } from '@angular/core';
import {
  CreateSectionInput,
  FieldConfigInput,
  FieldGroupInput,
  Form,
  FormNode,
  Section,
  UpdateSectionInput
} from '@app/core/model/other/field-config';
import {
  DropLocation
} from '@app/features/main/views/management/organization/field-configuration/form-config-datagrid/form-config-datagrid.component';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { IRowNode } from 'ag-grid-community';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SectionService {
  public readonly sectionGraphqlFragment: DocumentNode;

  private appManager: AppManager = inject(AppManager);
  private translate: TranslateService = inject(TranslateService);
  private generalService: GeneralService = inject(GeneralService);

  constructor() {

    this.sectionGraphqlFragment = gql`
      fragment SectionInfo on Section {
        id
        organizationId
        code
        formCode
        order
        customOptions
        label
        entityType
        emptyLabel
        fieldGroupsList {
          code
          order
          label
          emptyLabel
          tooltip
          fieldGroupType
          customOptions
          fieldConfigsList {
            fieldCode
            order
            customOptions
            field {
              code
              label
              tooltip
              entityType
              fieldType
              checkType
              computed
              parentPathList
              fieldValuesList
              validatorsList {
                conditionsList {
                  field
                  operator
                  value
                }
                definition
                type
              }
            }
            conditionsToViewList {
              field
              operator
              value
            }
            conditionsToEditList {
              field
              operator
              value
            }
          }
          conditionsToViewList {
            field
            operator
            value
          }
          conditionsToEditList {
            field
            operator
            value
          }
        }
        conditionsToViewList {
          field
          operator
          value
        }
        conditionsToEditList {
          field
          operator
          value
        }
      }
    `;
  }

  /**
   * Get all Sections by form code.
   * @param formCode Form code.
   * @return Observable that emits a list of Sections.
   */
  public getSectionsByFormCode(formCode: string): Observable<Section[]> {
    const COMBINED_QUERY = gql`
      query FormSections($formCode: String!, $orgId: String!) {
        formSections(formCode: $formCode, organizationId: $orgId) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const QUERY_VAR = {
      formCode,
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Section, response.data['formSections'] as Section[]))
      );
  }

  /**
   * Get all Forms of an organization
   * @return Observable that emits a list of forms.
   */
  public getForms(): Observable<Form[]> {
    const COMBINED_QUERY = gql`
      query Forms($orgId: String!) {
        forms(organizationId: $orgId) {
          code
          entityType
        }
      }
    `;
    const QUERY_VAR = {
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => response.data['forms'] as Form[])
      );
  }

  /**
   * Get all Sections of an organization.
   * The sections are then grouped by their form codes and flattened to calculate the data path for Ag-Grid tree
   * @return Observable that emits a list of FormNodes.
   */
  public getAllFlattenedFormNodes(): Observable<FormNode[]> {
    const COMBINED_QUERY = gql`
      query AllSections($orgId: String!) {
        sections(organizationId: $orgId) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const QUERY_VAR = {
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Section, response.data['sections'] as Section[])),// Add a super level around the sections
        map(sections => this.groupByFormCode(sections)), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Creates a section given an input containing information about the section itself
   * such as the module it belongs to, its label and the order in which it needs to appear in the form
   * @param sectionInput Input containing information about the section
   * @return Observable containing the newly created section
   */
  public createSection(sectionInput: CreateSectionInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation CreateSection($createSectionInput: CreateSectionInput!, $organizationId: String!) {
        createSection(organizationId: $organizationId, createSectionInput: $createSectionInput){
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const MUTATION_VAR = {
      createSectionInput: sectionInput,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Section, response.data['createSection'] as Section[])),
        map(sections => this.groupByFormCode(sections)), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Updates a section given an input of a few properties
   * @param sectionId Id of the section to update
   * @param sectionInput Input containing a new label or new order
   * @return Observable containing the newly updated section
   */
  public updateSection(sectionId: string, sectionInput: UpdateSectionInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation UpdateField($sectionId: String!, $updateSectionInput: UpdateSectionInput!, $organizationId: String!) {
        updateSection(sectionId: $sectionId, updateSectionInput: $updateSectionInput, organizationId: $organizationId) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const MUTATION_VAR = {
      sectionId,
      updateSectionInput: sectionInput,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Section, response.data['updateSection'] as Section[])),
        map(sections => this.groupByFormCode(sections)), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Adds an existing field of the organization to the given section
   * @param sectionId The section in which to add a field
   * @param fieldCode The code of the field to add
   * @param order The order in which the field should appear in the Section
   * @param fieldConfigInput The input containing the fieldConfig properties and the order in which it needs to appear in the section
   * @return Observable containing the updated section
   */
  public addFieldToSection(sectionId: string, fieldCode: string, order: number, fieldConfigInput: FieldConfigInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation AddExistingFieldToSection($organizationId: String!, $sectionId: String!, $fieldCode: String!, $order: Int!, $fieldConfigInput: FieldConfigInput!) {
        addExistingFieldToSection(sectionId: $sectionId, organizationId: $organizationId, fieldCode: $fieldCode, order: $order, fieldConfigInput: $fieldConfigInput) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      sectionId,
      fieldCode,
      order,
      fieldConfigInput
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Section, response.data['addExistingFieldToSection'] as Section)),
        map(sections => this.groupByFormCode([sections])), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Updates an existing field of the organization in the given section
   * @param sectionId The section in which to update a fieldGroup
   * @param fieldGroupCode The code of the fieldGroup to update
   * @param configInput The input containing the fieldConfig properties and the order in which it needs to appear in the section
   * @return Observable containing the updated section
   */
  public updateFieldGroupInSection(sectionId: string, fieldGroupCode: string, configInput: FieldGroupInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation UpdateExistingFieldGroupInSection($organizationId: String!, $sectionId: String!, $fieldGroupCode: String!, $fieldGroupInput: FieldGroupInput!) {
        updateExistingFieldGroupInSection(sectionId: $sectionId, organizationId: $organizationId, fieldGroupCode: $fieldGroupCode, fieldGroupInput: $fieldGroupInput) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      sectionId,
      fieldGroupCode: fieldGroupCode,
      fieldGroupInput: configInput
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Section, response.data['updateExistingFieldGroupInSection'] as Section)),
        map(sections => this.groupByFormCode([sections])), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Deletes a form node from the section config datagrid
   * @param nodeToDelete the Ag-Grid row node to remove along with its children
   * @return Observable containing response if the operation was successful
   */
  public deleteSectionFormNodes(nodeToDelete: IRowNode): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation DeleteFormNodes($nodePath: [String]!, $organizationId: String!) {
        deleteSectionFormNodes(organizationId: $organizationId, nodePath: $nodePath) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;

    const MUTATION_VAR = {
      nodePath: nodeToDelete.data.hierarchy,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Section, response.data['deleteSectionFormNodes'] as Section[])),
        map(sections => this.groupByFormCode(sections)), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Moves a certain tree node from a source path to a target path
   * and rearranges the order property of both source and target sections appropriately
   * @param source the source node to be moved
   * @param target the target node to which the source node is moved
   * @param dropLocation indication of the drop location of the node after it is reordered (defaults to after)
   * @return Observable containing the reordered list of sections
   */
  public reorderSectionFormNodes(source: IRowNode,
                                 target: IRowNode,
                                 dropLocation: DropLocation = 'after'): Observable<FormNode[]> {
    const COMBINED_QUERY = gql`
      mutation ReorderFormNodes($orgId: String!, $source: [String!]!, $target: [String!]!, $placement: Int!) {
        reorderSectionFormNodes(organizationId: $orgId, sourceNodePath: $source, targetNodePath: $target, placement: $placement) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const QUERY_VAR = {
      orgId: this.appManager.currentOrganization.id,
      placement: dropLocation == 'before' ? -1 : +1,
      source: source.data.hierarchy,
      target: target.data.hierarchy
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Section, response.data['reorderSectionFormNodes'] as Section[])),
        map(sections => this.groupByFormCode(sections)), // Add a super level around the sections
        map(forms => this.flattenChildrenRecursively(forms))
      );
  }

  /**
   * Groups the sections by their form code and creates a super structure around them as a datagrid tree node
   * @param data the list of sections to group
   * @return a list of Forms, superstructure of the sections grouped by their form code
   */
  private groupByFormCode(data: Section[]): Form[] {
    return data.reduce((forms: Form[], section) => {
      let formCode = section.formCode;
      let existingForm = forms.find((s: { code: string; }) => s && s.code === formCode);
      if (existingForm) {
        existingForm.sections.push(section);
      } else {
        forms.push({
          label: `${this.translate.instant('LABEL.' + section.entityType)} - ${this.translate.instant('LABEL.FORM_' + formCode.toUpperCase())}`,
          code: formCode,
          entityType: section.entityType,
          graphqlTypename: 'Form',
          sections: [section]
        });
      }
      return forms;
    }, []);
  }

  /**
   * Flattens the children of a form node recursively and creates a list of FormNodes
   * containing the data path from the root FormNode to all children
   * @param data the form nodes to flatten
   * @param parent the parent node, if present
   * @param childHierarchy the hierarchy data structure to build
   * @return a list of FormNodes (forms, sections, fieldGroups, fieldConfigs) containing the hierarchy path from the root FormNode to all children
   */
  private flattenChildrenRecursively(data?: FormNode[], parent = null, childHierarchy = null): FormNode[] {
    let newData = [];
    if (!data) return newData; // Abort early if data is undefined

    data.forEach((initialRow) => {
      let parentHierarchy = [];
      initialRow.hierarchy = parentHierarchy;

      if (parent) {
        initialRow['parent'] = parent;
        parentHierarchy = [...childHierarchy];
        initialRow.hierarchy = parentHierarchy;
      }

      parentHierarchy.push(initialRow['code']);
      newData.push(initialRow);
      switch (initialRow['graphqlTypename']) {
        case 'Form':
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['sections'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        case 'Section':
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['fieldGroups'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        case 'FieldGroup':
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['fieldConfigs'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        default:
          break;
      }
    });
    return newData;
  }

}

