import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import {
  FullRowEditCellRendererComponent
} from '@app/shared/components/cell-renderer/full-row-edit-cell-renderer/full-row-edit-cell-renderer.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'percent-cell-edit',
  templateUrl: './percent-cell-edit.component.html',
  styleUrls: ['./percent-cell-edit.component.scss']
})
export class PercentCellEditComponent extends AbstractCellEditor {

  @ViewChild(MatInput, { static: false }) public input: MatInput;

  constructor(public fb: FormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: CustomCellEditorParams): void {
    super.agInit(params);
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    if (!this.fullRowEdit) {
      this.input.focus();
    }
  }

  /**
   * Gets called when focus should be put into the editor.
   */
  public focusIn(): void {
    this.input.focus();
  }

  /**
   * Gets called when focus is leaving the editor.
   */
  public focusOut(): void {
    if (!this.fullRowEdit) {
      return;
    }

    // Focus on the button row if it's the last editable cell on full row edit.
    const lastEditableCellColumnId = this.params.columnApi.getColumns().lastOrNull((column) => {
      return column.isCellEditable(this.params.node);
    }).getId();

    if (this.params.column.getId() === lastEditableCellColumnId) {
      const fullRowEditCellRenderer = this.params.api.getCellRendererInstances().find((cellRenderer) => {
        return cellRenderer instanceof FullRowEditCellRendererComponent && cellRenderer.params.rowIndex == this.params.rowIndex;
      }) as FullRowEditCellRendererComponent;
      fullRowEditCellRenderer.focusIn();
    }
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): any {
    return this.control.value;
  }

  public ngOnDestroy(): void {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update tooltip message in case of validation error
   * @param type Type of the validator in error
   * @param definition Definition of the validator in error
   */
  protected setTooltip(type: string, definition: string | number): void {
    this.errorTooltip = this.translateService.instant(
      `ERROR.FIELD_${type}`.toUpperCase(),
      { value: definition }
    );
  }
}
