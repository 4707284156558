import { ClientSubscription } from '@app/core/model/client/client-subscription';
import { Module } from '@app/core/model/client/module';
import { User } from '@app/core/model/client/user';
import { Entity } from '@app/core/model/entities/entity';
import CustomDuration from '@app/shared/extra/custom-duration';
import { Expose, Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

export class ClientSubscriptionModule extends Entity {
  public nbLicence: number;
  public limitedDuration: boolean;

  @Type(() => String)
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  public startDate: Dayjs;
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public endDate: Dayjs;
  @Type(() => Module)
  public module: Module;
  @Type(() => User)
  public userLicences: User[];
  @Type(() => ClientSubscription)
  public clientSubscription: ClientSubscription;

  @Expose()
  public get duration(): { asMillisecondsValue: number, asNumberValue: number, asString: string } {
    return CustomDuration.calculate(this.startDate, this.endDate);
  }

  @Expose()
  public toString(): string {
    return 'Abonnement au module ' + this.module.toString() + ' entre le ' + this.startDate + ' et le ' + this.endDate;
  }
}
