import { Component, inject } from '@angular/core';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Client } from '@app/core/model/client/client';
import { User } from '@app/core/model/client/user';
import { UsersService } from '@app/shared/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { finalize } from 'rxjs';

@Component({
  selector: 'invitation-cell-renderer',
  templateUrl: './invitation-cell-renderer.component.html',
  styleUrls: ['./invitation-cell-renderer.component.scss']
})
export class InvitationCellRendererComponent implements ICellRendererAngularComp {
  private usersService: UsersService = inject(UsersService);
  private snackbarManager: SnackbarManager = inject(SnackbarManager);
  private translate: TranslateService = inject(TranslateService);

  private user: User;
  private client: Client;

  public state: 'none' | 'accepted' | 'pending' | 'pending_resend' | 'sending' = 'accepted';
  public readonly Permission = PermissionEnum;

  /**
   * Initialize the component's data and state from AG-Grid's parameters.
   * @param params CellRenderer parameters.
   */
  agInit(params: ICellRendererParams<User, boolean> & { client: Client }): void {
    this.refresh(params);
  }

  /**
   * Refresh the component's data and state.
   * @param params CellRenderer parameters.
   * @return true.
   */
  refresh(params: ICellRendererParams<User, boolean | null> & { client: Client }): boolean {
    this.user = params.data;
    this.client = params.client;
    if (params.value === true) {
      this.state = 'accepted';
    } else if (params.value === false) {
      this.state = !!this.user.lastInvite?.isBefore(dayjs().subtract(1, 'hour')) ? 'pending_resend' : 'pending';
    } else {
      this.state = 'none';
    }
    return true;
  }

  /**
   * Call the UsersService to send an invitation to the User. The passed MouseEvent will be stopped from propagating.
   * @param event Click event.
   */
  onClickResend(event: MouseEvent) {
    event.stopPropagation();
    this.state = 'sending';
    this.usersService.resendInvitation(this.user, this.client)
      .pipe(finalize(() => this.state = 'pending'))
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.INVITATION_SENT')));
  }
}
