import { Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { thenReturn } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from '@services/field.service';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';


@Component({
  template: ''
})
export abstract class AbstractSidebar<T extends Entity> implements OnInit, OnDestroy {

  public readonly eventsOrigin = EventOriginEnum.SIDEPANEL;
  public readonly Permission = PermissionEnum;

  public validators: FieldValidator[] = [];

  /**
   * The current Entity data.
   */
  public entity: T;

  /**
   * Whether to display the asset sheet navigation link.
   * Default is false.
   */
  @Input() public displayAssetSheetLink = false;

  /**
   * List of fields to hide in the sidebar.
   * Default is an empty array.
   * @example ['asset'] will hide the asset field in the sidebar.
   * @deprecated Use another form template instead.
   */
  @Input() public fieldsToHide: string[] = [];

  /**
   * The ID of the form to display in the sidebar.
   */
  @Input() public formId: string;

  /**
   * Reference the entity service panel toggle Observable.
   */
  protected sidePanelToggle$: Observable<T | null>;

  /**
   * Reference the entity service update entities Observable.
   */
  protected entitiesUpdated$: Observable<T | T[]>;

  /**
   * List of network requests to execute after the entity is loaded.
   * All requests are executed in parallel.
   */
  protected afterLoadRequests: ((entity: T) => Observable<unknown>)[] = [];

  /**
   * List of synchronous actions to execute after the entity is loaded.
   */
  protected afterLoadActions: ((entity: T) => void)[] = [];

  protected destroy$ = new Subject<void>();

  // Injection
  protected formStateService = inject(FormStateService);
  protected fieldService: FieldService = inject(FieldService);
  protected snackbarManager = inject(SnackbarManager);
  protected translate: TranslateService = inject(TranslateService);
  protected singleEditService = inject(SingleEditService);
  protected appManager = inject(AppManager);
  protected analyticsService = inject(AnalyticsService);
  public accessManager = inject(AccessManager);
  public fileService = inject(FileService);

  /**
   * API call to update the Entity.
   * @param data The update input.
   * @returns The updated Entity.
   */
  protected abstract updateEntity(data: Record<string, any>): Observable<T>;

  /**
   * Close the side panel.
   */
  protected abstract closeSidePanel(): void;

  /**
   * Navigate to the Entity's Asset Sheet corresponding tab.
   */
  public abstract navigateToAssetSheet(): Promise<void>;

  /**
   * Listen to entity changes or updates refresh the corresponding data.
   */
  public ngOnInit(): void {
    // Update the Entity whenever a new value is set for a property
    this.formStateService.saved$
      .pipe(
        takeUntil(this.destroy$),
        filter(({root}) => !root),
        switchMap(({code, data}) => this.updateEntity(data).pipe(
            tap(() => {
              // force computed fields to recalculate
              this.formStateService.getPath(['computed']).next();
              // release the SAVING state to the next state
              this.formStateService.getPath([code, 'state']).next(FieldStateMode.AFTER_SAVE);
            }),
            // Resubscribe to the saved observable if an error occurred.
            catchError(() => {
              this.formStateService.getPath([code, 'state']).next(FieldStateMode.ERROR);
              return this.formStateService.saved$;
            })
          )
        )
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      });

    // Update the sheet's content whenever a new Entity is selected
    this.sidePanelToggle$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(entityData => {
          if (!!entityData) {
            // There is an Entity to display, listen to external changes to this Entity to update the side panel data
            return this.entitiesUpdated$
              .pipe(
                takeUntil(this.destroy$),
                map(updatedEntity => {
                  return Array.isArray(updatedEntity) ? updatedEntity.find(entity => entity.id === entityData.id) : updatedEntity;
                }),
                filter(updatedEntity => !!updatedEntity && updatedEntity.id === entityData.id),
                startWith(entityData)
              );
          } else {
            // There is no Entity to display, the side panel is closed, just propagate the null value
            return of(entityData);
          }
        }),
        tap(() => {
          this.singleEditService.singleEditSubject.next(null);

          // Unload current Entity
          if (this.appManager.currentEntity?.constructor === this.entity?.constructor) {
            this.appManager.unloadCurrentEntity();
          }
        }),
        filter(entitySheetData => !!entitySheetData),
        tap((entity: T) => {
          // Set current Entity
          this.entity = entity;
          this.appManager.currentEntity = entity;
        }),
        switchMap(entity => merge(
            ...this.afterLoadRequests.map(additionalLoad => additionalLoad(entity))
          )
            .pipe(thenReturn(entity))
        )
      )
      .subscribe(entity => this.afterLoadActions.forEach(action => action(entity)));
  }

  /**
   * Close the side panel when the Escape key is pressed.
   * @param event Keyboard event.
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeSidePanel();
    }
  }

  /**
   * Unsubscribe Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
