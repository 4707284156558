import { MediaMatcher } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import { IRelatedProject, Project, RelatedProject } from '@app/core/model/entities/project/project';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import {
  EntityChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/entity-chips-field-builder';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'projects-chips-field-builder',
  templateUrl: './projects-chips-field-builder.component.html',
  styleUrls: ['../chips-field-builder.component.scss']
})
export class ProjectsChipsFieldBuilderComponent extends EntityChipsFieldBuilderComponent {

  public noAsset = true;
  public initialProjects: RelatedProject[];

  @ViewChild('inputField') public declare inputField: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') public declare suggestions: MatAutocomplete;
  @ViewChild('trigger') public trigger: MatAutocompleteTrigger;

  public declare filteredEntities: Observable<RelatedProject[]>;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              fb: FormBuilder,
              private projectsService: ProjectsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService,
      fb
    );
  }

  /**
   * Load projects data. Then, create the form, and add the chips.
   */
  public ngOnInit(): void {
    // Load Projects
    const assetId = this.entity['assetId'];
    if (assetId) {
      this.noAsset = false;
      this.projectsService.loadProjects(assetId)
        .pipe(first())
        .subscribe((projects) => {
          this.noEntity = !(projects && projects.length > 0);
          this.fieldConfig.field.fieldValues = projects.map(projects => projects.toRelatedProject());
        });
    } else if (this.entity instanceof Project) {
      this.noAsset = false;
      this.projectsService.loadProjects()
        .pipe(
          first(),
          map(projects => projects.filter(project => this.entity.id !== project.id))
        )
        .subscribe((projects) => {
          this.noEntity = !(projects && projects.length > 0);
          this.fieldConfig.field.fieldValues = projects.map(projects => projects.toRelatedProject());
        });
    }

    this.initialProjects = this.getFieldValue();
    super.ngOnInit();
  }

  /**
   * Get the field value to display in read mode.
   * @return RelatedProject
   */
  public getFieldValue(): RelatedProject[] {
    return getValue(this.entity, this.fieldConfig.fieldPath)
      ?.map((project: IRelatedProject) => new RelatedProject(project)) ?? [];
  }

  /**
   * Navigate to Project sheet
   * @param projectId
   */
  public async navigateToProjectSheet(projectId: string): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.PROJECT,
      projectId
    );
    await this.projectsService.navigateToProjectSheet(projectId);
  }
}
