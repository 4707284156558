import { AbstractBean } from '@app/core/model/abstract-bean';
import { Client } from '@app/core/model/client/client';
import { ClientSubscriptionModule } from '@app/core/model/client/client-subscription-module';
import CustomDuration, { DurationResult } from '@app/shared/extra/custom-duration';
import { Expose, Transform, Type } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';

export class ClientSubscription extends AbstractBean {
  public nbUser: number;
  public contractId: string;

  @Type(() => String)
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  public startDate: Dayjs;
  @Transform(fn => dayjs(fn.value), {toClassOnly: true})
  @Type(() => String)
  public endDate: Dayjs;
  @Type(() => ClientSubscriptionModule)
  public activeSubscriptionModules: ClientSubscriptionModule[];
  @Type(() => Client)
  public client: Client;

  @Expose()
  public get isActive(): boolean {
    return dayjs().isAfter(this.startDate) && dayjs().isBefore(this.endDate);
  }

  /**
   * When creating a subscription, we use the number of months to calculate the end date. When fetching the database, since the start date time
   * can be inferior to the end time and we use the gap between them to calculate the subscription's number of months,
   * duration returns the accurate number of months minus one, so we need to reset time here to avoid errors.
   */
  @Expose()
  public get duration(): DurationResult {
    return CustomDuration.calculate(this.startDate.startOf('day'), this.endDate.startOf('day'));
  }

  @Expose()
  public toString(): string {
    return '';
  }

}
